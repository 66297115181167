.contact-page {
  height: 100vh;
  min-height: 500px;
  max-height: fit-content;
  padding-top: 70px;
}

.contact-banner {
  height: calc(100vh - 70px);
  min-height: calc(500px - 70px);
  max-height: fit-content;
  padding: 0 7%;
}

.contact-banner-items {
  height: inherit !important;
  max-height: inherit !important;
}


.contact-form{
  width: 85%;
}

.contact-form input,
.contact-form textarea {
  background: #ffffff;
  border: 3px solid #c0c8df;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  padding: 1em 0.5em;
  margin-bottom: 2rem;
}

/* .contact-form input {
  width: 48%;
} */
.contact-form input:focus {
  outline: var(--mak-primary-blue) !important;
  border: 3px solid var(--mak-primary-blue);
}

.contact-form textarea:focus {
  outline: var(--mak-primary-blue) !important;
  border: 3px solid var(--mak-primary-blue);
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #c9c4c4;
}

.contact-form button {
  border: none;
  background-color: var(--mak-primary-blue);
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #fdfafa;
  border-radius: 20px;
  padding: 0.8rem 2rem;
}

.contact-form h3 {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: var(--mak-primary-blue);
  padding-bottom: 2rem;
}

.contact-form {
  padding: 3% 8%;
}

.contact-banner-content {
  padding-left: 10%;
}

.contact-banner-content h1 {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 50px;
  color: var(--mak-orange);
}

.contact-banner-content p {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  color: var(--mak-primary-blue);
}

.contact-banner img {
  width: 80%;
}

@media (max-width: 1366px) {
  .contact-banner-content h1 {
    font-size: 45px;
    line-height: 50px;
  }
  .contact-banner-content p {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1024px) {
  .contact-banner-content h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .contact-banner-content p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 905px) {
  .contact-banner-content h1 {
    font-size: 42px;
    line-height: 58px;
  }

  .contact-form{
    width: 100%;
  }

  .contact-banner-content p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {

  .contact-page{
    height: 500px;
  }

  .contact-banner{
    height: calc(500px - 70px);
  }
  .contact-form input,
  .contact-form textarea {
    font-size: 24px;
    line-height: 31px;
  }
  .contact-form button {
    padding: 1rem 2rem;
    font-size: 24px;
    line-height: 31px;
  }

  .contact-form h3 {
    font-size: 30px;
    line-height: 38px;
  }

  .contact-banner-content h1 {
    font-size: 38px;
    line-height: 50px;
}

.contact-banner-content p {
  font-size: 16px;
  line-height: 26px;
}
}

@media (max-width: 767px) {
  .contact-banner-content {
    padding-left: 0;
    padding-top: 20px;
}
  .contact-page {
    height: fit-content;
  }

  .contact-banner {
    height: fit-content;
    padding-top: 25px;
  }
}

@media(max-width: 425px){
  .contact-form input, .contact-form textarea {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 1.5rem;
}

.contact-form button {
  padding: 0.5rem 1.5rem;
  font-size: 20px;
  line-height: 27px;
}
}

@media (max-width: 370px) {
  .contact-banner-content h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .contact-banner-content p {
    font-size: 16px;
    line-height: 26px;
  }

  .contact-banner-vector {
    height: 250px !important;
  }
}
