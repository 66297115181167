:root {
  --mak-orange: #F96232;
  --mak-primary-blue: #253AB5;
  --mak-sec-blue:  #6699FF;

  /* --header-font: 'libel suit';
  --primary-font: 'Product sans'; */
  --header-font: Poppins;
  --primary-font: Poppins;
}


@font-face{
  font-family: 'libel suit';
  src: url('./assets/fonts/libel-suit-rg.ttf')
}

@font-face{
  font-family: 'Product sans';
  src: url('./assets/fonts/ProductSansRegular.ttf')
}


@font-face{
  font-family: 'Product sans bold';
  src: url('./assets/fonts/ProductSansBold.ttf')
}


@font-face{
  font-family: "Product sans italic";
  src: url('./assets/fonts/ProductSansItalic.ttf')
}

@font-face{
  font-family: 'product sans italic bold';
  src: url('./assets/fonts/ProductSansBoldItalic.ttf')
}


@font-face{
  font-family: "Bite Chocolate";
  src: url('./assets/fonts/BiteChocolate.ttf');
}




.learn-btn {
  border: none;
  background-color: transparent;
  color: var(--mak-primary-blue);
  vertical-align: middle;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 14.381px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.learn-btn::after {
  content: url("./assets/images/seeMoreVector.svg");
  width: 16.44px;
  height: 16.44px;
  margin-left: 0.4rem;
}

.learn-btn:focus{
    outline: solid 1px gray;
    border: none;
}


.know-btn {
  border: none;
  background-color: transparent;
  color: var(--mak-orange);
  vertical-align: middle;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 14.381px;
  line-height: 22px;
  display: flex;
  align-items: center;
}


.know-btn::after {
  content: url("./assets/images/knowBtnVec.svg");
  width: 16.44px;
  height: 16.44px;
  margin-left: 0.4rem;
}

.know-btn:focus{
    outline: solid 1px gray;
    border: none;
}

.see-more-btn {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 14.381px;
  line-height: 22px;
  border: none;
  background-color: transparent;
  color: var(--mak-primary-blue);
  display: flex;
  align-items: center;
}


.see-more-btn::after{
    content: url('./assets/images/seeMoreVector.svg');
    width: 16.44px;
    height: 16.44px;
    margin-left: 0.4rem;
}


.see-more-btn:focus{
    border: none;
    outline: solid 1px gray;
}

@media(min-width: 1441px){
  #root{
    width: 1440px !important;
    margin: 0 auto;
  }
}
