.mak-home {
  padding-top: 70px;
  height: 100vh;
  width: 100%;
  max-height: 741px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat !important;
  background: #f7f9fe;
}

.mak-home-banner {
  height: calc(100vh - 70px);
  max-height: calc(741px - 70px);
  padding: 0 8%;
}
.mak-home-banner article {
  height: inherit;
  max-height: inherit;
}
.mak-banner-quote {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 60px;
  color: var(--mak-orange);
  margin-bottom: 6.7% !important;
}

.mak-banner-quote b {
  color: var(--mak-primary-blue);
}

.mak-banner-subText {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: var(--mak-primary-blue);
  margin-bottom: 6.7% !important;
}

.mak-story {
  min-height: fit-content;
  max-height: fit-content;
}

/* .mak-story article:last-child{
  padding: 0 8% 0 0;
} */

.mak-story article {
  min-height: inherit;
  max-height: fit-content;
}

/*  */

.mak-story-bg {
  background: url("../../assets/images/storyBg.svg");
  background-size: cover;
  background-repeat: no-repeat !important;
  background-position: center top;
  position: relative;
}

.mak-story-play {
  position: absolute;
  top: 45%;
  right: -50px;
  z-index: 2;
}

.mak-story-txt {
  padding: 8% 8% 8% 14.8%;
  background-color: #2b42c8;
}

.mak-story-txt small {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.mak-story-txt h2 {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: white;
  margin-bottom: 26px;
}

.mak-story-txt h3 {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: white;
}

.mak-story-txt p {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: justify;
  color: white;
  margin: 4% 0;
}

.mak-wwd {
  min-height: fit-content;
  max-height: fit-content;
  background-size: cover;
  background-position: center left;
  /* background-repeat: no-repeat !important; */
  background: white;
  padding: 9.8% 8% 9.8% 8%;
}

.mak-wwd small {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #f96232;
}

.mak-home-titles {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: var(--mak-primary-blue);
}

.mak-wwd h2 {
  margin-bottom: 6.15%;
}

.mak-wwd-item h3 {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 24.6531px;
  line-height: 29px;
  color: var(--mak-primary-blue);
  margin-top: 15px;
  margin-bottom: 0 !important;
}

.mak-wwd-item p {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 14.381px;
  line-height: 22px;
  color: var(--mak-primary-blue);
  margin: 15px 0;
}

.mak-wwd-item {
  padding: 30px 30px 30px 39px;
  background-color: white;
  min-height: 453px;
  transition: all 0.5s;
  margin-bottom: 40px;
  border: 3.08163px solid rgba(37, 58, 181, 0.17);
}

.mak-wwd-item:hover {
  background-color: white;
  border: 3.08163px solid #10228c;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px 10px rgba(0, 0, 0, 0.25);
  padding: 30px 30px 30px 39px;
  transition: all 0.5s;
}

.mak-owp {
  background: #2b42c8;
  min-height: 300px;
}

.mak-owp-titles {
  padding: 2% 8% 2% 8%;
}

.mak-owp-titles h2 {
  color: white !important;
}

.mak-contact {
  /* min-height: calc(100vh - 100px); */
  max-height: fit-content;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat !important;
  mix-blend-mode: multiply;
  background: linear-gradient(
      0deg,
      rgba(21, 35, 113, 0.8),
      rgba(21, 35, 113, 0.8)
    ),
    url("../../assets/images/contactBg.png");

  padding: 5% 8%;
}

.mak-contact input {
  background-color: transparent;
  border: none;
  border-bottom: 3px solid rgba(255, 255, 255, 0.55);
  width: 50%;
  margin-bottom: 3%;
  padding: 8px 0.2rem;
  color: white;
}

.mak-contact input:first-child {
  margin-top: 3%;
}

.mak-contact input:focus {
  outline: 1px solid gray;
}

.mak-contact input::placeholder {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 24.6531px;
  line-height: 116.5%;
  color: rgba(255, 255, 255, 0.36);
}

.mak-contact h2 {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: var(--mak-orange);
  margin-bottom: 26px;
}

.mak-contact h3 {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
}

.mak-works {
  padding: 5% 8%;
  background-image: url("../../assets/images/ourWorksBg.png");
}

.mak-works h2 {
  color: var(--mak-blue);
}

.mak-works-box {
  width: 762px;
}

.mak-works-box-layout {
  padding: 28px 18px !important;
}

.mak-works-box-layout h4 {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 116.5%;
  color: #ffffff;
}

.mak-works-box-layout p {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 116.5%;
  color: #ffffff;
}

.mak-partners h2 {
  color: var(--mak-primary-blue);
  padding: 2% 8% 0 8%;
}

.mak-partner-slide {
  width: 100%;
  padding: 3% 0 4% 0;
  overflow: hidden;
}

.mak-home-subtitles {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 116.5%;
  color: #ffffff;
}

.mak-process-grid {
  /* display: grid; */
  /* grid-template-columns:  30% 20% 20% 30%; */
  padding-bottom: 4%;
}

.mak-process-grid-item {
  /* border: 1px solid white; */
}

.mak-process-grid-item p {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 116.5%;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
}

.process-line {
  position: relative;
  height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 24px 0;
}

.process-line hr {
  background-color: #fbbc09;
  height: 3px;
  margin: 0;
}

.y-ring {
  position: absolute;
  left: 46%;
  top: 6%;
}

.y-triangle {
  position: absolute;
  right: 0;
  top: 17%;
}

.partner-logo {
  width: 50%;
  height: 80%;
  object-fit: contain;
  object-position: center;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s all ease-in;
}

.partner-logo:hover{
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transition: 0.3s all ease-in;
}

.partner-space {
  height: 120px;
}

.wwd-sm {
  display: none;
}
.wwd-lg {
  display: flex;
}
.slick-slide {
  outline: none;
}

.slick-next {
  right: -72px;
  top: -14px;
  z-index: 10;
}

.slick-next::before {
  color: var(--mak-primary-blue) !important;
}

.slick-prev::before {
  color: var(--mak-primary-blue) !important;
}

.slick-prev {
  left: 14px;
  top: -14px;
  z-index: 10;
}

.animated-logo {
  width: 100%;
}

.contact-mak {
  background: url("../../assets/images/contactBg.png"), var(--mak-primary-blue);
  height: 100vh;
  max-height: 671px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-mak > div {
  height: 70vh;
  max-height: 400px;
  vertical-align: bottom;
}




.contact-girl {
  width: 60%;
  height: inherit;
  max-height: inherit;
  object-fit: contain;
  object-position: center;
}


.contact_girl{
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}



.contact-img-shift {
  display: flex;
  align-items: flex-end;
}

.contact-txt-shift {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* .contact-txt-shift h2 {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: normal;
  font-size: 87px;
  line-height: 104px;
  text-align: center;
  color: #fefeff;
}

.contact-txt-shift button {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #191a6c;
  border: none;
  background: #fefeff;
  box-shadow: 0px 3px 70px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
} */

.contact-header {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #fefeff;
}

.contact-section-button {
  background: #fefeff;
  box-shadow: 0px 3px 70px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 0.6rem 1.6rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #191a6c;
}


.contact-section-content{
  padding-bottom: 15%;
}
@media (max-width: 1101px) {
  .mak-banner-quote {
    font-size: 32px;
    line-height: 52px;
  }

  .mak-banner-subText {
    font-size: 20px;
    line-height: 32px;
  }

  .mak-story-txt h3 {
    font-size: 32px;
    line-height: 50px;
  }

  .mak-story-txt p {
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width: 887px) {
  .mak-banner-quote {
    font-size: 30px;
    line-height: 50px;
  }

  .mak-banner-subText {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 834px) {
  .no-br {
    display: none !important;
  }
  .mak-story-txt h3 {
    font-size: 30px;
    line-height: 48px;
  }
  .mak-story-txt p {
    font-size: 18px;
    line-height: 30px;
  }

  .contact-header{
    font-size: 30px;
    line-height: 42px;
  }

  .contact-section-button{
    font-size: 18px;
    line-height: 24px;
    padding: 0.4rem 1.4rem;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .wwd-sm {
    display: unset;
  }
  .wwd-lg {
    display: none;
  }
}

@media (max-width: 767px) {

  .contact-img-shift{
    display: none;
  }

  .contact-mak{
    height: 500px;
    max-height: 500px;
  }
  .mak-wwd-item {
    min-height: 353px;
  }
  .hidden {
    display: none !important;
  }
  .wwd-sm {
    display: unset;
  }
  .wwd-lg {
    display: none;
  }
  .mak-banner-quote {
    font-size: 40px;
    line-height: 60px;
  }

  .mak-contact input {
    width: 70%;
  }

  .mak-process-grid-item {
    margin: 25px 0;
  }

  .process-line {
    visibility: hidden;
    margin: 6px 0;
  }
  .mak-story-play {
    position: absolute;
    top: 45%;
    right: 0px;
    left: 40%;
    display: none;
  }

  .mak-story-txt {
    padding: 8% 8% 8% 8%;
    padding-left: calc(8% - 15px);
  }
  .mak-story article {
    min-height: 400px;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  .mak-works-box {
    width: 100% !important;
  }
}

@media (max-width: 700px) {
  .mak-wwd-item h3 {
    font-size: 22.6531px;
    line-height: 27px;
  }
}

@media (max-width: 510px) {
  .mak-story-txt p {
    text-align: left;
    margin: 5% 0;
  }

  .mak-home-subtitles {
    font-size: 14px;
}
  
.mak-owp-titles {
  padding: 5% 8% 5% 8%;
}
  .mak-banner-quote {
    font-size: 34px;
    line-height: 54px;
  }

  .mak-process-grid-item p {
    font-size: 14px;
    line-height: 116.5%;
  }

  .mak-process-grid-item {
    margin: 10px 0;
  }
  .process-line {
    visibility: hidden;
    margin: 0px 0;
  }
  .mak-contact input {
    width: 100%;
  }

  .mak-contact h3 {
    font-family: var(--header-font);
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 50px;
    color: #ffffff;
  }

  .mak-story-bg {
    min-height: 300px !important;
  }
}

@media (max-width: 425px) {
  .contact-mak{
    height: 400px;
    max-height: 400px;
  }
  .contact-mak > div{
    height: 70vh;
    max-height: 150px;
  }
  .mak-home-titles {
    font-size: 30px;
    line-height: 50px;
  }
  .mak-banner-quote {
    font-size: 32px;
    line-height: 52px;
  }
  .mak-story-txt h3 {
    font-size: 28px;
    line-height: 46px;
  }

  .mak-contact h2 {
    font-size: 30px;
    line-height: 50px;
  }
}

@media (max-width: 400px) {
  .mak-banner-quote {
    font-size: 30px;
    line-height: 50px;
  }

  .mak-story-txt h3 {
    font-size: 26px;
    line-height: 44px;
  }

  .mak-banner-subText {
    font-size: 16px;
    line-height: 28px;
  }

  .mak-story-txt p {
    font-size: 16px;
    line-height: 28px;
  }

  .mak-wwd-item h3 {
    font-size: 20.6531px;
    line-height: 25px;
  }
}
