.banner_container {
  height: 100vh;
  min-height: 300px;
  max-height: 630px;
  padding-top: 70px;
}

.aboutBanner {
  background:linear-gradient(rgba(0,0,0, 0.3), rgba(0,0,0,0.3)), url("../../assets/images/about/aboutBanner.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 70px);
  min-height: calc(300px - 70px);
  max-height: calc(630px - 70px);
  padding: 0 5%;
}

.aboutBanner h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 96px;
  color: #ffffff;
  padding-top: 12%;
  margin-bottom: 0;
}

.aboutBanner div {
  height: 2px;
  width: 14%;
  background-color: #fffcfb;
}

#whoWeAre {
  background: url("../../assets/images/about/whoWeAreBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 500px;
  max-height: fit-content;
  padding: 2% 5%;
}

#whoWeAre section {
  min-height: inherit;
}

.who-we-are-image {
  min-height: inherit;
}

.who-we-are-text {
  min-height: 500px;
}

.about_title {
  font-family: "Bite Chocolate";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  color: var(--mak-primary-blue);
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.who-we-are-image img{
  max-width: 388px;
  max-height: 356px;
  width: 100%;
}
/* .who-we-are-text p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: justify;
  color: #000000;
} */

.why-items p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 0;
}

.why-items img{
  max-width: 83px;
  max-height: 89px;
}

.about_txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: justify;
  color: #000000;
}

.scroll-over2 {
  height: 600px;
  width: 100%;

  background: url("../../assets/images/about/scrollOver2.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.scroll-over1 {
  height: 600px;
  width: 100%;

  background: url("../../assets/images/about/scrollOver1.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#our-values {
  padding: 5% 5%;
  background: url("../../assets/images/about/whoWeAreBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 500px;
  max-height: fit-content;
}

.about-values h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--mak-primary-blue);
}

.about-values p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  color: #000000;
  width: 85%;
  margin: 0 auto;
}

.about-values img{
  max-height: 109px;
  max-width: 108px;
  object-fit: contain;
  margin-bottom: 20px;
}

#why-choose-us {
  padding: 4% 3%;
  background: url("../../assets/images/about/whoWeAreBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 600px;
  max-height: fit-content;
}

.whyUsImg {
  width: 100%;
  max-height: 520px;
  max-width: 491px;
  object-fit: contain;
  object-position: center;
}

/*////////// MEDIA QUERIES /////////////////////*/
@media (max-width: 768px) {
  .banner_container {
    max-height: 400px;
  }
  .aboutBanner {
    max-height: calc(400px - 70px);
  }

  .aboutBanner h1 {
    font-size: 54px;
    line-height: 86px;
  }

  .about_title{
    font-size: 30px;
  }
}


@media(max-width: 767px){
  #whoWeAre section{
    min-height: fit-content;
  }
}


@media(max-width: 499px){
  .about-values p{
    width: 100%;
  }
}