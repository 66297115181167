#service-top-banner {
  min-height: 426px;
  height: 100vh;
  background:var(--mak-primary-blue) url("../../assets/images/ourServices/bannerBg.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding: 0 5.3% 30px 5.3%;
  padding-top: 120px;
  max-height: fit-content;
  border-radius: 30px;
}

#service-top-banner article {
  /* width: 70%; */
}

#service-top-banner h1 {
  padding-bottom: 25px;
}

#service-top-banner p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* text-align: justify; */
  color: #ffffff;
}

.services-items {
  padding: 0 8%;
}
.service-subtitle {
  padding: 0 8%;
}

.service-shift {
  /* padding-left: 6% !important; */
}

.service-subtitle h3 {
  color: var(--mak-orange);
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.service-subtitle div {
  height: 2px;
  width: 72px;
  background-color: var(--mak-orange);
}

.services-desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  /* text-align: justify; */
  /* width: 90%; */
}

.services-titles {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;

  color: #253ab5;
}

.services-image-r {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.services-image-l {
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}

.services-image img {
  width: 100%;
  height: 100%;
  max-height: 212px;
  max-width: 206px;
  object-fit: contain;
}

.colored-ring {
  display: unset;
}
.alter-service-image {
  display: none;
  width: 40%;
  max-height: 212px;
  max-width: 206px;
  object-fit: contain;
}

#support-services {
  padding: 5% 5%;
  background: url("../../assets/images/ourServices/pssBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pss-tag div {
  background-color: #6eeeff;
  height: 2px;
  width: 72px;
}

.pss-tag h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
}
.pss-items {
  padding-bottom: 25px;
}
.pss-items h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  width: 95%;
}

.pss-items p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  text-align: justify;
  color: #ffffff;
  width: 95%;
}

.pss-items img {
  margin-bottom: 18px;
  margin-left: 8px;
  width: 100%;
  max-width: 116px;
  max-height: 116px;
}

#industry-served {
  padding: 5% 8%;
}

#industry-served h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #aba5a5;
}

.industry-served-grid {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.served-items {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}

.served-items img {
  width: 100%;
  max-width: 100px;
  padding-bottom: 11px;
}

.served-items p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 37px;
  color: #b3acaa;
  text-align: center;
}

.service-vector {
  width: 100%;
  height: 100%;
  max-width: 530px;
  max-height: 331px;
  object-fit: contain;
}

.service-relative {
  position: relative;
}


.oox{
  animation: float 2s ease-in-out 0s infinite alternate;
}


@keyframes float{
    0%{
        transform: translate(0px, 30px)
    }
    100%{
        transform: translate(0px, 0px);

}
}

@media (max-width: 1066px) {
  .served-items p {
    font-size: 20px;
    line-height: 32px;
  }
  .services-titles {
    font-size: 26px;
    line-height: 41px;
  }

  .pss-items h3 {
    font-size: 24px;
    line-height: 39px;
  }

  .pss-items p {
    font-size: 16px;
    line-height: 31px;
  }

  #service-top-banner p {
    font-size: 16px;
    line-height: 26px;
  }

  .service-subtitle {
  }

  .services-desc {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .hide-vector {
    display: none !important;
  }

  #industry-served h2 {
    font-size: 30px;
    line-height: 48px;
  }
  .pss-tag h2 {
    font-size: 18px;
    line-height: 30px;
  }

  .served-items p {
    font-size: 14px;
    line-height: 26px;
  }

  .pss-tag div {
    width: 52px;
  }
  .pss-items h3 {
    font-size: 22px;
    line-height: 37px;
    width: 100%;
  }

  .pss-items p {
    font-size: 16px;
    line-height: 29px;
    width: 100%;
  }
  #service-top-banner article {
    width: 100%;
  }
  .services-desc {
    font-size: 16px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .alter-service-image {
    display: unset;
  }
  .service-image-switch {
    display: none;
  }
  /* .services-image {
    justify-content: left;
  } */
  .services-image img {
    width: 40%;
    object-fit: contain;
  }
  .services-desc {
    width: 100%;
  }
  .colored-ring {
    display: none;
  }

  .service-shift {
    padding-left: 0% !important;
  }
}

@media (max-width: 700px) {
  .industry-served-grid {
    grid-template-columns: 33% 33% 33%;
  }
}

@media (max-width: 502px) {
  #service-top-banner article {
    width: 100%;
  }

  .pss-items img {
    width: 40%;
  }
}

@media (max-width: 472px) {
  .industry-served-grid {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 452px) {
  .services-titles {
    font-size: 24px;
    line-height: 39px;
  }
}

@media (max-width: 417px) {
  .services-titles br {
    display: none;
  }
}

@media (max-width: 394px) {
  .services-titles {
    font-size: 22px;
    line-height: 37px;
  }
}
