.mak-footer {
  padding: 5% 8% 1% 8%;
}

.mak-footer h4 {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #253ab5;
}

.mak-footer h4 {
  margin-bottom: 24px;
}

.mak-footer h3 {
  margin-bottom: 50px;
}

.mak-footer ul li,
.mak-footer p,
.mak-footer address {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(50, 51, 55, 0.55);
}

.mak-footer ul {
  padding: 0 !important;
  margin: 0 !important;
}

.mak-footer ul li {
  margin-bottom: 15px;
}

.get_info {
  font-weight: 600 !important;
  margin-bottom: 22px;
}

.mak-copy {
  padding: 4% 0;
}

.curved-input {
  border: 3px solid #e1dbdb;
  box-sizing: border-box;
  border-radius: 30px;
  width: 80%;
}

.mak-footer a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #191a6c;
  text-decoration: none;
}
