.mak-header {
  height: 70px;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 100;
  padding: 0 8%;
}

.mak-header ul {
  margin: 0;
}



.list_nav li:first-child {
  margin-left: 0;
}



.hams {
  display: none;
}

.hams div {
  background-color: var(--mak-primary-blue);
  height: 3px;
  width: 24px;
}

.hams div:nth-child(2) {
  margin: 3px 0;
}

.mak-side-nav {
  position: fixed;
  top: 70px;
  right: 1px;
  z-index: 20;
  color: var(--primary-color);
  background: rgba(255, 255, 255, 0.1);
  /* filter: blur(5px); */
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 80%;
  min-height: 300px;
  max-height: fit-content;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px);
  padding: 2rem 2.5rem;
  transition: right 1s ease-out;
  display: none;
}

.no-side-bar {
  padding: 2rem 2.5rem;
  position: fixed;
  right: -100%;
  top: 70px;
  width: 80%;
  z-index: 20;
  min-height: 300px;
  max-height: fit-content;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px);
  transition: right 1s ease-out;
  color: var(--primary-color);
  background: rgba(255, 255, 255, 0.1);
  display: none;
}

.mak-side-nav ul {
  margin: 0 !important;
}



.mak-side-nav li:active {
  text-decoration: underline;
}


.mak-side-nav li:last-child {
  margin-bottom: 0;
}

.no-side-bar li {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 20px;
}

.no-side-bar li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .mak-side-nav {
    width: 60%;
    display: none;
  }

  .no-side-bar {
    width: 60%;
    display: none;
  }
}
@media (max-width: 767px) {
  .blur-out {
    filter: blur(3px);
  }

  .on-blur {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 10;
    top: 70px;
  }
  .hams {
    display: unset;
  }

  .list_nav {
    display: none !important;
  }
  .mak-side-nav {
    width: 60%;
    display: unset;
  }

  .no-side-bar {
    width: 60%;
    display: unset;
  }
}

@media (min-width: 1441px) {
  .mak-header {
    width: 1440px;
  }
}

@media (max-width: 500px) {
  .mak-side-nav {
    width: 90%;
  }
}
.open_top {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.open_middle {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.open_bottom {
  opacity: 1;
  transition: all 0.5s linear;
}

.close_top {
  transform: translate(0, 6px) rotate(45deg);
  transition: all 0.5s ease;
}

.close_bottom {
  opacity: 0;
  transition: all 0.5s linear;
}
.close_middle {
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}

/* NEW HEADER CHANGES */
.mak-header-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: var(--mak-primary-blue) !important;
}

.mak-header li {
  margin-right: 0 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-left: 8%;
  color: var(--mak-sec-blue);
}

.mak-header li:hover {
  color: var(--mak-orange);
}


.mak-nav-active{
  color: var(--mak-orange) !important;
}


.no-side-bar li {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 26px;
  color: var(--mak-primary-blue) !important;
}

.mak-side-nav li {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 26px;
  color: var(--mak-primary-blue) !important;
}